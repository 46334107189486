<template>
    <v-dialog v-model="api.isSuccesful" height="700" width="700">
        <v-card class="pa-3">
            <div class="d-flex justify-center">
                <v-icon size="150" color="green">
                    bi-check-circle-fill
                </v-icon>
            </div>
            <v-card-text class="py-4">
                <div class="d-flex justify-center font-weight-bold text-h4">
                        Success
                </div>
                <div class="d-flex justify-center align-center text-center my-2 text-h6">
                        {{ api.success }}
                </div>
                <div class="d-flex justify-center" >
                    <v-btn color="primary" @click="$emit('close')">
                        Okay
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    props:['api'],
    methods:{
       
    }
}
</script>