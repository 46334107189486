<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <a-success-index :api="this.api" @close="redirectCompany"/>
        
        <!-- <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

        </v-skeleton-loader> -->
        <v-card class="pb-4">
            <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                New Company 
            </v-toolbar>
            <v-row no-gutters>
                <v-col class="pa-3"> 
                    <div class="d-flex justify-start">
                        <v-text-field label="Company Name " :rules="requiredRules" v-model="companies.company_name" dense outlined>

                        </v-text-field>
                    </div>
                    <div class="d-flex justify-start">
                        <v-autocomplete v-model="companies.account_type"  :rules="requiredRules" :items="AccountCompanyOptions" label="Company Type" dense outlined>

                        </v-autocomplete>
                    </div>
                    
                    
                    <div class="d-flex justify-start" v-if="companies.account_type!=null && companies.account_type!='First'">
                        <v-autocomplete v-model="companies.account_sub_type" :items="AccountSubCompanyOptions" label="Subscription Type" dense outlined>

                        </v-autocomplete>
                    </div>
                    <div class="d-flex justify-start">
                        <v-textarea label="Address" v-model="companies.company_address" dense outlined>

                        </v-textarea>
                    </div>

                </v-col>
                <v-col class="pa-3">
                   
                    <div class="d-flex justify-start">
                        <v-text-field label="Coupon" v-model="companies.company_coupon" dense outlined>
                        </v-text-field>
                    </div>
                    <div class="d-flex justify-start">
                        <v-text-field label="Industry" v-model="companies.company_industry" dense outlined>

                        </v-text-field>
                    </div>
                   
                   
                    <div class="d-flex justify-start">
                        <v-text-field label="SSM No." v-model="companies.company_ssm" dense outlined>
                        </v-text-field>
                    </div>
                   
                    
                </v-col>
            </v-row>
            <div>
                <v-card class="ma-5 pa-2">
                    <div class="d-flex justify-start mx-2 mt-4">
                        <p class="text-h5 font-weight-bold">
                            User
                        </p>
                    </div>
                    <v-row no-gutters>
                        <v-col>
                            <div class="px-2">
                                <div class="d-flex flex-row">

                                    <div class="flex mr-2">
                                        <v-text-field v-model="users.user_fname" :rules="requiredRules" label="First Name" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                    <div class="flex">
                                        <v-text-field v-model="users.user_lname" :rules="requiredRules" label="Last Name" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                    <div class="flex mr-2">
                                        <v-text-field v-model="users.email" :rules="requiredRules" label="Email" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                    <div class="flex">
                                        <v-text-field v-model="users.mobile" :rules="requiredRules" label="Mobile" dense outlined>
    
                                        </v-text-field>
                                    </div>

                                </div>
                                <div>
                                    <v-autocomplete dense outlined :rules="requiredRules" v-model="users.user_account_type" :items="AccountUserOptions" label="User Account Type">

                                    </v-autocomplete>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="RoleOptions" :rules="requiredRules" v-model="users.user_role" dense outlined label="Role">

                                    </v-autocomplete>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="AAERoleOptions" v-model="users.aae_role" dense outlined label="AAE Role">

                                    </v-autocomplete>
                                </div>
                                <div class="d-flex flex-row">
                                    <div class="flex mr-2">
                                        <v-autocomplete @change="updateMainCategory" :rules="requiredRules" :items="MainCategoryOptions" clearable v-model="users.main_category" dense outlined label="Main Category">

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category==='TaxPOD Client'">
                                        <v-autocomplete :items="TaxPODClientOptions" v-model="users.sub_category" dense outlined label="Sub category" >

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category==='Internal Staff'">
                                        <v-autocomplete :items="InternalStaffOptions" v-model="users.sub_category" dense outlined label="Sub Category">

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category===null || users.main_category==='SmartCFO Client'">
                                        <v-autocomplete  disabled :items="TaxPODClientOptions" v-model="users.sub_category" dense outlined label="Sub category" >

                                        </v-autocomplete>
                                    </div>
                                </div>
                                <!-- <div class="d-flex flex-row">
                                    <div>
                                        <span class="text-h7 font-weight-bold m">
                                            Status :
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-switch v-model="users.isEnable">
                                        <template v-slot:label>
                                            <v-chip color="green" class="text-h7 white--text" v-if="users.isEnable">
                                                Active
                                            </v-chip>
                                            <v-chip color="red" class="text-h7 white--text" v-else>
                                                Deactive

                                            </v-chip>


                                        </template>
                                    </v-switch>

                                </div> -->
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <!-- <div  v-for="(item,index) in users" :key="index">
                <v-card class="ma-3 pa-2">
                    <div class="d-flex justify-start mx-2 mt-4">
                        <p class="text-h5 font-weight-bold">
                            User {{ index+1 }}
                        </p>
                    </div>
                    <v-row no-gutters >
                        <v-col>
                            <div class="px-2">
                                <div class="d-flex justify-start">
                                    <div class="pr-3">
                                        <v-text-field v-model="item.user_name" label="First Name" dense outlined>
                
                                        </v-text-field>
            
                                    </div>
                                    <div>
                                        <v-text-field v-model="item.user_lname" label="Last Name" dense outlined>
            
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-text-field v-model="item.user_email" label="Email" dense outlined>
                                        
                                    </v-text-field>                            
                                </div>
                                <div class="d-flex justify-start">
                                    <v-text-field v-model="item.user_mobile" label="Mobile Phone" dense outlined>
            
                                    </v-text-field>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-switch v-model="isVerified[index]"  >
                                        <template v-slot:label>
                                            <v-chip color="green" class="text-h7 white--text" v-if="isVerified[index]===true">
                                                {{ isVerifiedCustomValue }}
                                            </v-chip>
                                            <span v-else>
                                                {{ isNotVerifiedCustomValue }}
        
                                            </span>
                                        </template>
                                    </v-switch>
                                </div>
        
                                <div class="d-flex justify-start">
                                    <v-switch v-model="isEnable[index]">
                                        <template v-slot:label>
                                            <v-chip color="green" class="text-h7 white--text" v-if="isEnable[index]===true">
                                                {{ isEnableCustomValue }}
                                            </v-chip>
                                            <span v-else>
                                                {{ isNotEnableCustomValue }}
                                            </span>
                                        </template>
                                    </v-switch>
                                </div>
                            </div>
        
                        </v-col>
                        <v-col class="px-3">
                            <div class="d-flex justify-start">
                                <v-text-field label="Main Category" v-model="item.user_subscription_type" dense outlined>
        
                                </v-text-field>
                            </div>
                            <div class="d-flex justify-start">
                                <v-text-field v-model="item.user_branch" label="Sub Category" dense outlined>
                                    
                                </v-text-field>
                                
                            </div>
                            <div class="d-flex justify-start">
                                    <v-autocomplete :items="AAERoleOptions" v-model="item.user_role" dense outlined>
            
                                    </v-autocomplete>
                                </div>
                        </v-col>
                    </v-row>

                </v-card>
            </div> -->
            <div class="d-flex justify-end pt-5 px-5">

                <v-btn color="primary" plain @click="()=>{
                    $router.go(-1)
                }">
                    Cancel
                </v-btn>
                <v-btn :loading="api.isLoading" color="primary" @click="()=>{
                    this.isPending =true;
                }">
                    Confirm
                </v-btn>
            </div>
            <AError :api="this.api" class="mt-3 mx-4"/>

            <div  v-if="nameExisted" class="d-flex justify-end px-4 pb-4">

            <v-alert class="mt-3" width="50%" density="compact" type="warning" >
                <v-spacer>

                </v-spacer>
                <div>
                    There is already existed company with the same name. Do you still want to register this company ?
                </div>
                <div class="d-flex justify-end mt-4" >
                    <v-btn color="primary" class="mx-2 font-weight-bold" outlined :to="{name:'PageCompaniesDetail',params:{id:this.existedCompanyId}}" plain>
                        Check Company
                    </v-btn>
                    <v-btn color="primary" @click="createNewCompany">
                        Confirm Register
                    </v-btn>
                </div>
            </v-alert>
            </div>
        </v-card >
    </v-container>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessIndex from '../../components/common/ASuccessIndex.vue';

export default{
    data: () => ({
        requiredAsterik:'<span style="color:red"> * </span>',
        requiredRules:[v=>!!v || 'This field is required'],
        isPending: false,
        companies: {

        },
        users: {

        },
        nameExisted:false,
        companyId:null,
        existedCompanyId:null,
        isVerified: false,
        isVerifiedCustomValue: 'Verified',
        isNotVerifiedCustomValue: 'Not Verified',
        isEnable: false,
        isEnableCustomValue: 'Enable',
        isNotEnableCustomValue: 'Disable',
        AccountCompanyOptions:["First","Second","Third","Fourth","Fifth"],
        AccountSubCompanyOptions:["Renewal","First Subscription"],
        AAERoleOptions:["Client","Consultant","Tax Expert","Admin","Manager"],
        RoleOptions: ["User", 'Admin', "Instructor","Manager","Consultant","Tax Expert"],
        AccountUserOptions:['Primary',"Secondary"],
        AAERoleSelected: "Client",
        MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
        TaxPODClientOptions : ["Normal","Private Client","Non-Private Client","Accountant"],
        InternalStaffOptions:["HQ & COE","CM","Penang","Johor","Klang","Boss Boleh"],
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    computed: {
        ComputeCompanyIsVerified(verified_at) {
            return (verified_at != null) ? true : false;
        },
        MappingSwitchIsVerified: {
            get(tempIsVerified) {
                return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
            },
            set(value) {
                this.isVerified = value === this.isVerifiedCustomValue;
            }
        },
        // MappingSwitchIsEnable: {
        //     get() {
        //         return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
        //     },
        //     set(value) {
        //         this.isEnable = value === this.isEnableCustomValue;
        //     },
        // }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="checkRedundantCompany"){
                if(resp.status=="fail"){
                    this.nameExisted =true;
                    this.existedCompanyId = resp.data.id;
                }
                if(resp.status=="success"){

                    this.createNewCompany();
                    return 1;
                }

                
            }
            if(resp.class=="store"){
                if(resp.status=="fail"){
                    this.api.isError = true;
                    this.api.error = resp.data;
                    this.api.isLoading = false;
                    return 1;
                }
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Create New Company";
                this.companyId = resp.data;
            }

            // What I have don yesterday
            // Finish the active task module. With an adjustment from
            // Hanis design. Today got a new enhancement request from xin ying 
            //
            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            // let fetchCompaniesDetailApi = this.fetchCompaniesDetail();
            // this.$api.fetch(fetchCompaniesDetailApi);
        },
        updateMainCategory(){
            if(this.users.main_category==null || this.users.main_category==="SmartCFO Client"){
                this.users.sub_category = null;
            }
        },
        fetchCompaniesDetail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/full/client/id/" + this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        checkRedundantCompanyName(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/company/name";
            let formData = new FormData;
            formData.append('company_name',this.companies.company_name);
            tempApi.params = formData;
            return tempApi;
        },
        createNewCompany(){
            // alert(this.users.isEnable);
            // return 1;
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/company";
            let formData = new FormData;
            formData.append('company_name',this.companies.company_name);
            formData.append('company_address',this.companies.company_address);
            formData.append('company_ssm',this.companies.company_ssm);
            formData.append('company_coupon',this.companies.company_coupon);
            formData.append('company_industry',this.companies.company_industry);
            formData.append('company_acc_category',this.companies.account_type);
            if(this.companies.account_sub_type!=null){

                formData.append('company_acc_sub_category',this.companies.account_sub_type);
            }
            // formData.append('status',(this.users.isEnable==='undefined')?false:this.users.isEnable);
            formData.append('status',true);

            formData.append('user_fname',this.users.user_fname);
            formData.append('user_lname',this.users.user_lname);
            formData.append('email',this.users.email);
            formData.append('mobile',this.users.mobile);
            formData.append('main_category',this.users.main_category);
            formData.append('sub_category',this.users.sub_category);
            formData.append('user_role',this.users.user_role);
            formData.append('aae_role',this.users.aae_role);
            formData.append('user_acc_category',this.users.user_account_type);

            tempApi.params = formData;

            this.$api.fetch(tempApi);

        },
        submit(){
            this.isPending = false;
            let checkRedundantCompanyNameApi = this.checkRedundantCompanyName();
            this.$api.fetch(checkRedundantCompanyNameApi);            
            // this.isPending = false;
            // let formData = new FormData;
            // let tempApi = this.$_.clone(this.api);
            // tempApi.method = "POST";
            // tempApi.url = process.env.VUE_APP_SERVER_API+"/company/full/client/id/"+this.$router.history.current.params.id;
            // formData.append("company_name",this.companies.company_name);
            // formData.append("company_address",this.companies.company_address);
            // formData.append("company_coupon",this.companies.company_coupon);
            // formData.append('company_ssm',this.companies.company_ssm);
            // formData.append('company_industry',this.companies.company_industry);
            // formData.append('company_branch',this.companies.company_branch);
            // formData.append('subscription_type',this.companies.company_subscription_type);
            // formData.append('company_coupon',this.companies.company_coupon);
            // formData.append('causer_id',this.$store.getters.getUserId);

            // this.users.forEach(user => {
            //     let tempUser =
            //     {
            //         "user_id": user.user_id,
            //         "user_lname": user.user_lname,
            //         "user_fname": user.user_name,
            //         "user_email": user.user_email,
            //         "user_mobile": user.user_mobile,
            //         "user_role":user.user_role,
            //         "user_branch": user.user_branch,
            //         "user_verified_at": this.isVerified[this.users.indexOf(user)] ,
            //         "user_status":this.isEnable[this.users.indexOf(user)],
            //         "subscription_type": user.user_subscription_type,
            //     }
            //     formData.append('users[]',JSON.stringify(tempUser));
            // });

            // tempApi.params = formData;

            // this.$api.fetch(tempApi);

        },
        cancelSubmit(){
            this.isPending = false;
        }, 
        redirectCompany(){
            if(this.companyId!=null){
                this.$router.push({name:'PageCompaniesDetail',params:{id:this.companyId}});

            }
        } 
    },
    components: { AConfirmation, ASuccessIndex }
}
</script>